import Swiper from "swiper";

export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcImg: require('./images/pc/bannerVideo.mp4'),
            //             phoneImg: require('./images/mobile/bannerVideo.mp4'),
            //             descList: [
            //                 {
            //                     descName: '防爆化工四足机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: '100+',
            //                     sort: 3
            //                 }, {
            //                     descName: '50KG',
            //                     sort: 4
            //                 }, {
            //                     descName: '四足防爆技术',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '电液复合驱动',
            //                     sort: 6
            //                 }, {
            //                     descName: '场最拓展',
            //                     sort: 7
            //                 }, {
            //                     descName: '负载重量',
            //                     sort: 8
            //                 }, {
            //                     descName: '高度控制',
            //                     sort: 9
            //                 }],
            //             type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcImg: require('./images/pc/banner2.jpg'),
            //             phoneImg: require('./images/mobile/banner2.jpg'),
            //             descList: [
            //                 {
            //                     descName: '四足防爆技术',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex IIB T4 Gb',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: '采用正压、本安、隔爆、胶封等复合防爆设计，突破国内四足机器人防爆技术难题，打破技术壁垒。符合国家防爆标准，使得四足机器人能够在石油、化工、仓储、港口等防爆区域进行安全巡检作业，对未知、非结构环境具有较高的适应性，能够实现在楼梯、台阶、缝隙、障碍物、狭小空间等复杂路面上运动，有效降低生产安全风险。',
            //                     sort: 3
            //                 }],
            //             type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcImg: require('./images/pc/banner3.jpg'),
            //             phoneImg: require('./images/mobile/banner3.jpg'),
            //             descList: [
            //                 {
            //                     descName: '高可靠性',
            //                     sort: 1
            //                 }, {
            //                     descName: '高强度、优异疲劳寿命材质和结构设计助力安全',
            //                     sort: 2
            //                 }, {
            //                     descName: '核心部件由社合金、7系铝合金、合金结构钢等材质打造，结合部分零件锻造毛还、渗/氮、喷丸等工艺，具备强度高、执疲劳等特点;电气系统在驱于内正压须里，温度等冗余检测，提升整机安全性和可靠性。',
            //                     sort: 3
            //                 }],
            //             type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcImg: require('./images/pc/banner4.jpg'),
            //             phoneImg: require('./images/mobile/banner4.jpg'),
            //             descList: [
            //                 {
            //                     descName: '电液复合驱动',
            //                     sort: 1
            //                 }, {
            //                     descName: '使用安全，断电或非行走状态下重载、整机长时间保持功能，意外触地冲击和过载溢流保护功能。出众的整机位置控制和导航性能，重复定位精度5cm。能够在巡检的同时，代替人工去完成一些复杂沉重的搬运工作，整机50kg负载重量。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcImg: require('./images/pc/banner5.jpg'),
            //             phoneImg: require('./images/mobile/banner5.jpg'),
            //             descList: [
            //                 {
            //                     descName: '应用领域拓展',
            //                     sort: 1
            //                 }, {
            //                     descName: '防爆化工四足机器人可应用于石油、化工、天然气、电力等行业，可适应各种铺装及非铺装路况的复杂场景，并向森林、无人区、煤矿、公关救援、沙漠、岩地等应用领域拓展。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcImg: require('./images/pc/banner6.jpg'),
            //             phoneImg: require('./images/mobile/banner6.jpg'),
            //             descList: [
            //                 {
            //                     descName: '应用领域拓展',
            //                     sort: 1
            //                 }, {
            //                     descName: '防爆化工四足机器人可应用于石油、化工、天然气、电力等行业，可适应各种铺装及非铺装路况的复杂场景，并向森林、无人区、煤矿、公关救援、沙漠、岩地等应用领域拓展。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcImg: require('./images/pc/banner7.jpg'),
            //             phoneImg: require('./images/mobile/banner7.jpg'),
            //             descList: [
            //                 {
            //                     descName: '应用领域拓展',
            //                     sort: 1
            //                 }, {
            //                     descName: '防爆化工四足机器人可应用于石油、化工、天然气、电力等行业，可适应各种铺装及非铺装路况的复杂场景，并向森林、无人区、煤矿、公关救援、沙漠、岩地等应用领域拓展。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcImg: require('./images/pc/banner8.jpg'),
            //             phoneImg: require('./images/mobile/banner8.jpg'),
            //             descList: [
            //                 {
            //                     descName: '应用领域拓展',
            //                     sort: 1
            //                 }, {
            //                     descName: '防爆化工四足机器人可应用于石油、化工、天然气、电力等行业，可适应各种铺装及非铺装路况的复杂场景，并向森林、无人区、煤矿、公关救援、沙漠、岩地等应用领域拓展。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 8
            //         },
            //         {
            //             pcImg: require('./images/pc/banner9.jpg'),
            //             phoneImg: require('./images/mobile/banner9.jpg'),
            //             descList: [
            //                 {
            //                     descName: '应用领域拓展',
            //                     sort: 1
            //                 }, {
            //                     descName: '防爆化工四足机器人可应用于石油、化工、天然气、电力等行业，可适应各种铺装及非铺装路况的复杂场景，并向森林、无人区、煤矿、公关救援、沙漠、岩地等应用领域拓展。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 9
            //         },
            //         {
            //             pcImg: require('./images/pc/banner10.jpg'),
            //             phoneImg: require('./images/mobile/banner10.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆化工四足机器人',
            //                     sort: 0
            //                 },
            //                 {
            //                     descName: '规格参数',
            //                     sort: 1
            //                 }, {
            //                     descName: '外形尺寸(长×宽×高)',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: '防爆等级',
            //                     sort: 3
            //                 },
            //                 {
            //                     descName: '负载重量',
            //                     sort: 4
            //                 },
            //                 {
            //                     descName: '爬坡能力',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '越障能力',
            //                     sort: 6
            //                 },
            //                 {
            //                     descName: '主导航方式',
            //                     sort: 7
            //                 },
            //                 {
            //                     descName: '整备质量',
            //                     sort: 8
            //                 },
            //                 {
            //                     descName: '防护等级',
            //                     sort: 9
            //                 }, {
            //                     descName: '行走速度',
            //                     sort: 10
            //                 },
            //                 {
            //                     descName: '涉水深度',
            //                     sort: 11
            //                 },
            //                 {
            //                     descName: '续航时间',
            //                     sort: 12
            //                 },
            //                 {
            //                     descName: '重复导航定位精度',
            //                     sort: 13
            //                 }, {
            //                     descName: '≤1250mm×660mm×1060mm',
            //                     sort: 14
            //                 },
            //                 {
            //                     descName: 'Ex IIB T4 Gb',
            //                     sort: 15
            //                 },
            //                 {
            //                     descName: '50kg',
            //                     sort: 16
            //                 },
            //                 {
            //                     descName: '≤30°',
            //                     sort: 17
            //                 },
            //                 {
            //                     descName: '200mm',
            //                     sort: 18
            //                 },
            //                 {
            //                     descName: '激光雷达+IMU',
            //                     sort: 19
            //                 },
            //                 {
            //                     descName: '≤150kg',
            //                     sort: 20
            //                 },
            //                 {
            //                     descName: 'IP56',
            //                     sort: 21
            //                 }, {
            //                     descName: '1.5m/s',
            //                     sort: 22
            //                 },
            //                 {
            //                     descName: '≤300mm',
            //                     sort: 23
            //                 },
            //                 {
            //                     descName: '2-3h',
            //                     sort: 24
            //                 },
            //                 {
            //                     descName: '≤±5cm',
            //                     sort: 25
            //                 }],
            //             type: 'image',
            //             sort: 10
            //         },
            //         {
            //             pcImg: require('./images/pc/banner11.jpg'),
            //             phoneImg: require('./images/mobile/banner11.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人高性能版',
            //                     sort: 1
            //                 }, {
            //                     descName: '豪华高性能 巡检新标杆',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 11
            //         }
            //     ],

            elmentList: [],
            aboutswiper: null,
        }
    },
    mounted() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
                this.aboutSwiper();
                if (!this.$store.state.pageset.pcshow) {
                    let video = document.getElementById('videoOne');
                    video.play();
                  }
            }, 100);
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        openPdf() {
            window.open(this.elmentList[11].pcImg)
        },
        // 初始化轮播图
        aboutSwiper() {
            this.aboutswiper = new Swiper('.mySwiper', {
                pagination: {
                    el: '.swiper-pagination',
                    // clickable: true,
                },
                autoplay: true,
                speed: 2000,
                loop: true,
                // effect: 'fade',//切换效果
                // fadeEffect: {
                //     crossFade: true  //开启淡出。过渡时，原slide透明度从1->0（淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                //     //false  关闭淡出。过渡时，原slide透明度为1（不淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                // },
            });
        },
    }
}